// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("C:\\Development\\Clients\\Meble Sukces\\Meble Bike Sukces Website\\bike-sukces-website\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Development\\Clients\\Meble Sukces\\Meble Bike Sukces Website\\bike-sukces-website\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Development\\Clients\\Meble Sukces\\Meble Bike Sukces Website\\bike-sukces-website\\.cache\\data.json")

